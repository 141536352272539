import React, {useState, useEffect} from 'react';
import {usePageContext} from "../../Page/page_context";
import "../challenge_modifier.css";
import ChallengeModifierHolder from "./challenge_modifier_holder";
import {useStatusContext} from "../../Page/status_context";
import {Button} from "@mui/material";

const ChallengeModifier = ({game_id, addons}) => {
    const {currentLabel} = usePageContext();
    const {setStatus, setLoading} = useStatusContext();
    const [labelData, setLabelData] = useState({});
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            if (game_id === null) return;
            setStatus({text: "Awaiting data...", color: 1});
            await fetch(process.env.REACT_APP_API_URL_REQUEST + `?gameid=${game_id}&read=${currentLabel}`,{
                credentials: 'include',
                method: 'GET',
            })
                .then(response => {
                    if (!response.ok) { // Check if the response status is not in the 200-299 range
                        throw new Error('Network response was not ok: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    setLabelData(data);
                    setStatus({text: "Awaiting successful...", color: 0});
                })
                .catch(error => {
                    setStatus({text: "Awaiting unsuccessful...", color: 1});
                    // Optionally handle the error further, e.g., by setting an error state, logging, etc.
                });
        }
        fetchData()
        setLoading(false);
        setFetched(true);
    }, [currentLabel, game_id, setLoading, setStatus]);

    const handleDataEdit = (value, dataKey, inner_key) => {
        setStatus({text: "Editing", color: 2});
        setLoading(true);
        setLabelData({...labelData, [dataKey]: {...labelData[dataKey], [inner_key]: value}});
        setLoading(false);
    };

    const submitChanges = async () => {
        if (game_id === null) return;
        if (fetched) {
            const keyvalue = JSON.stringify(labelData);
            const addUrl = process.env.REACT_APP_API_URL_REQUEST + `?gameid=${game_id}&add=${currentLabel}&keyvalue=${encodeURIComponent(keyvalue)}`;
            try {
                await fetch(addUrl, {
                    method: 'GET',
                    credentials: 'include',
                });
                // console.log('Label added successfully');
                setLoading(false);
                setStatus({text: "Success...", color: 0});
            } catch (error) {
                setLoading(false);
                setStatus({text: "Failed...", color: 1});
                console.error('Error adding label:', error);
            }
        }
    };

    const handleSave = async () => {
        setLoading(true);
        setStatus({text: "Saving data...", color: 2});
        await submitChanges();
        setLoading(false);
        setStatus({text: "Success...", color: 0});
    };
    return (
        <div>
            <div className={'challenge-modifier row-2'}>
                <div>
                    <br/>
                    {Object.keys(labelData).map(dataKey =>
                        dataKey === "date" || dataKey === "extras" ?
                            null :
                            <ChallengeModifierHolder
                                key={dataKey} //
                                dataKey={dataKey}
                                data={labelData}
                                eventTrigger={handleDataEdit}
                            />
                    )}
                </div>
                <div className={'col_end'}>
                    <Button onClick={handleSave} className="button">Save</Button>
                </div>
            </div>
            <div>{addons && <addons currentLabel={currentLabel} labelData={labelData} fetched={fetched}/>}</div>
        </div>
    );
};

export default ChallengeModifier;
