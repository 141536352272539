/*CompletionPercentagePieChart.js*/

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const CompletionPercentagePieChart = ({ game_id }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const mockData = [
                { label: 'Completed', value: 70 },
                { label: 'Not Completed', value: 30 }
            ];
            setData([
                {
                    labels: mockData.map(item => item.label),
                    values: mockData.map(item => item.value),
                    type: 'pie'
                }
            ]);
        };

        fetchData();
    }, [game_id]);

    return (
        <Plot
            data={data}
            layout={{
                title: 'Daily Challenge Completion Rate',
                height: 400,
                width: 480
            }}
        />
    );
};

export default CompletionPercentagePieChart;
/*CompletionPercentagePieChart.js*/