import React, { useEffect, useState } from 'react'; // Import React hooks for managing side effects and state.
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper } from '@mui/material'; // Import Material UI components for UI layout.

import { post, useAdministrationContext } from "./administration_context"; // Import custom context and post method for handling data.
import {useAlertContext} from "../Page/alert_context"; // Import custom alert context to show alerts.

const PageManager = () => {
    const { user } = useAdministrationContext(); // Get 'user' data from administration context.
    const [pages, setPages] = useState([]); // State to store all pages.
    const [userPages, setUserPages] = useState([]); // State to store pages assigned to a specific user.
    const [username, setUsername] = useState(""); // State to store the username.

    const { showAlert } = useAlertContext(); // Get 'showAlert' function from alert context to display alerts.

    useEffect(() => {
        const fetchData = async () => {
            const allPages = await post({ method: 'getPages' });
            const userSpecificPages = await post({ method: 'getPagesOfUser', id: user[0] });
            const usernameFromID = await post({ method: 'getUsernameFromID', user_id: user[0] });

            setPages(allPages);
            setUserPages(userSpecificPages);
            setUsername(usernameFromID[0][0]); // Assuming the username is nested within two arrays.
        };

        fetchData();
    }, [user]);

    const isPageChecked = (page) => {
        // Check if a page is already assigned to the user.
        return Array.isArray(userPages) && userPages.some(userPage => JSON.stringify(userPage) === JSON.stringify(page));
    };

    const handleCheckboxChange = async (page) => {
        // Determine whether to add or remove a page for the user.
        const method = isPageChecked(page) ? 'removePage' : 'addPage';
        const data = {
            method,
            parent_id: page[0], // Assuming page structure includes parent_id.
            page_id: page[1], // Assuming page structure includes page_id.
            version_id: page[2], // Assuming page structure includes version_id.
            user_id: user, // User ID to which the page is being assigned or removed.
            game_id: page[3] // Assuming page structure includes game_id.
        }

        await post(data); // Send request to add or remove page access.

        // Display appropriate alert depending on whether page was added or removed.
        if(method === 'addPage'){
            showAlert(<span>Added access to page <strong>{page[1]}</strong> for user <strong>{username}</strong>! (ID: {user})</span>, "success");
        }
        else{
            showAlert(<span>Removed access to page <strong>{page[1]}</strong> for user <strong>{username}</strong>! (ID: {user})</span>, "warning");
        }

        // Update 'userPages' state based on whether the page was added or removed.
        const updatedUserPages = method === 'addPage'
            ? [...userPages, page] // Add the page to the user's assigned pages.
            : userPages.filter(userPage => JSON.stringify(userPage) !== JSON.stringify(page)); // Remove the page from user's assigned pages.

        setUserPages(updatedUserPages); // Update state with new list of user pages.
    };

    return (
        <TableContainer component={Paper}> {/* Container for the table, using Material UI Paper component. */}
            <Table>
                <TableHead> {/* Table header for column titles. */}
                    <TableRow>
                        <TableCell padding="checkbox"></TableCell> {/* Empty cell for checkbox column. */}
                        <TableCell>Game</TableCell> {/* Column header for Game. */}
                        <TableCell>Variant</TableCell> {/* Column header for Variant. */}
                        <TableCell>Game ID</TableCell> {/* Column header for Game ID. */}
                    </TableRow>
                </TableHead>
                <TableBody> {/* Table body containing rows of pages. */}
                    {pages.map((page, index) => (
                        <TableRow key={index}> {/* Map through pages and create a row for each page. */}
                            <TableCell padding="checkbox">
                                <Checkbox
                                    className="custom-checkbox" // Custom styling for the checkbox.
                                    checked={isPageChecked(page)} // Determine if the checkbox should be checked.
                                    onChange={() => handleCheckboxChange(page)} // Handle checkbox changes for adding/removing access.
                                />
                            </TableCell>
                            <TableCell>{page[0]} {page[1]}</TableCell> {/* Display Game information. */}
                            <TableCell>{page[2]}</TableCell> {/* Display Variant information. */}
                            <TableCell align={"center"}>{page[3]}</TableCell> {/* Display Game ID, centered. */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PageManager; // Export PageManager component for use in other parts of the application.
