import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {useSidebarContext} from "../Sidebar/sidebar_context";


const StatisticsData = ({game_id}) => {
    const {brand, dates} = useSidebarContext();
    const [statistics, setStatistics] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;

        const fetchData = async () => {
            if (game_id === null) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_STATISTICS}?method=getGeneralStatistics&gameid=${game_id}&date=${selectedDate.toISOString().split('T')[0]}`, {
                    signal,
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (!signal.aborted) {
                    setStatistics(data);
                }
            } catch (error) {
                if (!signal.aborted) {
                }
            }
        };

        fetchData();

        return () => {
            controller.abort();
        };
    }, [selectedDate]); // Keeping the original dependency array as requested


    const changeDate = (date) => {
        let startDate = new Date(dates[0]);
        let endDate = new Date(dates[1]);
        // console.log(startDate, endDate, date)
        if (date < startDate) {
            date = startDate;
        } else if (date > endDate) {
            date = endDate;
        }
        // console.log(startDate, endDate, date)
        setSelectedDate(new Date(date));
    }

    return (
        <>
            <div className={'d-flex d-md-none date-container'}>
                <div className={"default_page_style"} style={{color: "var(--dark)"}}>Choose a date:</div>
                <DatePicker
                    className={'date-picker'}
                    selected={selectedDate}
                    onChange={(date) => changeDate(date)}
                    dateFormat="dd-MM-yyyy"
                />
            </div>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Statistic</th>
                    <th style={{justifyContent: "space-between", display: "flex"}}>Metric
                        <div className={'d-none d-md-block'}>
                            <DatePicker
                                className={'date-picker'}
                                selected={selectedDate}
                                onChange={(date) => changeDate(date)}
                                dateFormat="dd-MM-yyyy"
                            />
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(statistics).map(([key, value], index) => (
                    <tr key={index}>
                        <td>{key === 'date' ? 'Date' : key}</td>
                        <td>
                            <div style={{justifyContent: "right", display: "flex", paddingRight:"1rem"}}>{value}</div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
};

export default StatisticsData