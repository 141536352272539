import React, {useEffect, useState} from "react";
import {useStatusContext} from "../Page/status_context";
import {useSidebarContext} from "../Sidebar/sidebar_context";
// import { Box, Dialog, DialogTitle, DialogContent, DialogContentText , Button } from "@mui/material";
import {Box, Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, Button} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid/DataGrid';

const updateChallenge = (gameId, date, newChallenge) => {
    // console.log(gameId, date, newChallenge);
    // console.log('sent');
    fetch(process.env.REACT_APP_DAPIV2 + "/statistics", {
        method: "PUT",
        credentials: 'include',
        body: JSON.stringify({
            method: "updateDailyChallenge",
            game: gameId,
            date: date,
            info: newChallenge
        })
    })
        .then(response => {
            if (response.ok) {
                // Handle successful response
            } else {
                // Handle error response
                throw new Error('Failed to update challenge');
            }
        })
        .catch(error => {
            // Handle error here
            console.error("Error updating challenge:", error);
        });
};

const ChallengeViewer = ({game_id}) => {
    const {dates, setAuthenticated} = useSidebarContext();
    const {setStatus, setLoading} = useStatusContext();
    const [challenges, setChallenges] = useState([]);
    const [error, setError] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [setSelectedDate] = useState('');
    const [enteredNumber, setEnteredNumber] = useState('');
    const [rowID, setRowID] = useState(0);


    // const challenge_processor = (challenge) =>{
    //     const [name, number] = challenge.split("-");
    //     switch (name) {
    //         case "reach":
    //             return "Achieve " + number + " points.";
    //         case "clear":
    //             return "Clear " + number + " rows.";
    //         case "combo":
    //             return "Achieve " + number + " combinations in a row without errors.";
    //         case "speed":
    //             return "Create a new combination within " + number + " seconds.";
    //         case "time":
    //             return "Clear the board within " + number + " minutes.";
    //         default:
    //             return "Error";
    //     }
    // }

    useEffect(() => {
        if (game_id === null) return;
        setLoading(true); // Set loading state to false after login attempt
        const url = process.env.REACT_APP_DAPIV2 + "/statistics?method=getDailyChallenge&gameid=" + game_id + "&date=" + dates[0] + "_2100-01-01";
        // console.log(url);
        const fetchData = async () => {
            setStatus({text: "Awaiting data...", color: 2,});
            await fetch(url, {
                headers: {
                    'Connection': 'close',
                },
                credentials: 'include',
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else if (response.status === 401){
                        setAuthenticated(false);
                    }
                    else {
                        throw new Error('Failed to await challenges');
                    }
                })
                .then(data => {
                    // console.log(data);
                    // Assume data.date and data.daily_challenge_info are the arrays we need to process
                    const challenges = data.date.map((date, index) => {
                        return {
                            id: index + 1, // Increment index by 1 to start id from 1 instead of 0
                            date: date,
                            challenge: data.daily_challenge_info[index] // Match each date with the corresponding challenge
                        };
                    });

                    challenges.reverse(); // Reverse the order of challenges
                    setChallenges(challenges); // Set the state with the transformed data
                    setStatus({text: "Awaiting successful!", color: 0}); // Set a success status
                })
                .catch(error => {
                    setStatus({text: "Error Awaiting data", color: 1});
                    setError(error.message);
                });
            setStatus({text: "Awaiting successful...", color: 0});
        }
        fetchData();
        setLoading(false);
    }, [dates, game_id, setLoading, setStatus]);

    if (error) {
        return <div>Error Awaiting challenges: {error}</div>;
    }

    const columns = [
        {field: "date", headerName: "Date", width: 150},
        {field: "challenge", headerName: "Challenges", flex: 1},
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(event) => {
                        event.stopPropagation(); // Prevent row click event
                        handleEditClick(params.row); // Handler for opening the popup
                    }}
                    className="Button"
                    style={{backgroundColor: "var(--main)"}} // Add your own styles here
                >
                    Edit
                </Button>
            ),
        },
    ];

    const handleEditClick = (row) => {
        setSelectedChallenge(row); // Assuming row contains all the data you need
        // console.log(row);
        setRowID(row.id);
        const [challenge, value] = row.challenge.split("-");
        setEnteredNumber(value); // Update the value input
        setSelectedOption(challenge); // Set the dropdown selection
        setOpenPopup(true); // Finally, open the popup
    };


    const handleSave = async () => {
        updateChallenge(game_id, selectedChallenge.date, `${selectedOption}-${enteredNumber}`);
        const updatedChallenge = {
            id: rowID, // Ensure this matches the identifier in the challenges array
            date: selectedChallenge.date,
            challenge: `${selectedOption}-${enteredNumber}`, // Recreate the challenge string or however it should be formatted
        };

        // Now, update the challenges state with the updated challenge
        const updatedChallenges = challenges.map(challenge =>
            challenge.id === rowID ? updatedChallenge : challenge
        );

        setChallenges(updatedChallenges);
        // console.log(game_id);
        handleClosePopup(); // Close the popup when done
        // Optionally reset the originalDate and other states if necessary
    };


    const handleClosePopup = () => {
        // Reset state variables to their defaults
        setOpenPopup(false);
        setSelectedDate('');
        setEnteredNumber('');
        setSelectedOption('');
        setRowID(0);
        // Any other state resets as necessary
    };

    const getMenuItems = () => {
        if (game_id === 1 || game_id === 6|| game_id === 7) {
            return [
                    <MenuItem key="clear" value="clear">clear</MenuItem>,
                    <MenuItem key="reach" value="reach">reach</MenuItem>];
        } else if (game_id === 2 || game_id === 10) {
            return [
                    <MenuItem key="combo" value="combo">combo</MenuItem>,
                    <MenuItem key="speed" value="speed">speed</MenuItem>,
                    <MenuItem key="time" value="time">time</MenuItem>];
        } else if (game_id === 9 || game_id === 11 || game_id === 3 || game_id === 12){
            return [
                    <MenuItem key="placeholder" value="placeholder">placeholder</MenuItem>];
        }
        else if (game_id === 13 || game_id === 14){
            return [
                <MenuItem key="difficulty" value="difficulty">difficulty</MenuItem>];
        }
        else {
            return [];
        }
        // You can add more conditions or a default case if needed
    };

    return (
        <Box sx={{height: 400, borderBoxSize: 0, border: 0}}>
            <DataGrid
                rows={challenges}
                columns={columns}
                pageSize={5}
                hideFooter
                getRowId={(row) => row.id} // Specify the id property
                loading={challenges.length === 0}
                shadeDirection={'right'}
                border-radius='0'
                sx={{
                    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
                        color: 'rgba(255, 255, 255, 0.56)',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'var(--main)',
                        color: 'var(--light)',
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        borderRadius: 0, // Remove any border radius from the column headers
                    },
                }}
            />
            <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                <DialogTitle>Challenge Details</DialogTitle>
                <DialogContent>
                    {/* Dropdown Menu */}
                    <Select
                        fullWidth
                        value={selectedOption} // Use state variable for the dropdown
                        onChange={(e) => {
                            // console.log('New selected option:', e.target.value);
                            setSelectedOption(e.target.value);
                        }}
                        displayEmpty
                    >
                        {getMenuItems()}
                    </Select>

                    {/* Numerical Input or Another Text Field */}
                    <TextField
                        fullWidth
                        type="number" // Or "text", depending on what 'value' represents
                        value={enteredNumber} // Use state variable for the value
                        onChange={(e) => setEnteredNumber(e.target.value)}
                        sx={{mt: 2}}
                    />

                    {/* Save Button */}
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{mt: 2}}
                        className="Button"
                        style={{backgroundColor: "var(--main)"}} // Add your own styles here
                    >
                        Save
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
// <Table game_id={game_id} headers={["Date", "Challenges"]} data={challenges}>

export default ChallengeViewer;