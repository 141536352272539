import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { useAlertContext } from '../../Page/alert_context';
import { useSidebarContext } from '../../Sidebar/sidebar_context';

const ExistingSudotetrisViewer = ({ game_id }) => {
    const { setAuthenticated } = useSidebarContext();
    const [sudotetrisPuzzles, setSudotetrisPuzzles] = useState({ scheduled: [], available: [], used: [], rejected: [] });
    const [activeTab, setActiveTab] = useState('scheduled');
    const { showAlert } = useAlertContext();

    const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [rescheduleDate, setRescheduleDate] = useState("");
    const [currentSudotetris, setCurrentSudotetris] = useState(null);
    const [changeSudotetris, setChangeSudotetris] = useState(false);
    const [method, setMethod] = useState("");
    const today = new Date().toISOString().split('T')[0];
    const [openLevelDialog, setOpenLevelDialog] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState("");


    useEffect(() => {
        if (game_id === null) return;
        const controller = new AbortController();
        const {signal} = controller;
        const fetchData = async () => {
            try {
                const url = process.env.REACT_APP_API_URL_STATISTICS + '?method=getDailyChallengeSudotetris&gameid=' + game_id;
                // console.log(url);

                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Connection': 'close',
                    },
                    signal
                });
                if (!response.ok) {
                    if (response.status === 401) {
                        setAuthenticated(false);
                    }
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const rawData = await response.json(); // Assuming rawData is an array of these objects

                const dataArray = Array.isArray(rawData) ? rawData : [rawData];

                const data = dataArray.reduce((acc, {name, data, difficulty, status, date, level}) => {
                    // Initialize category array if not already present
                    if (!acc[status]) {
                        acc[status] = [];
                    }
                    // Since 'bw' represents the data you're interested in, we map it to a key named 'data' for consistency with your previous structure
                    acc[status].push({name, data, difficulty, date, level});

                    if (acc && Array.isArray(acc['level'])) {
                        acc['level'].sort((a, b) => {
                            return parseInt(a.level) - parseInt(b.level);
                        });
                    } else {
                        // console.error('acc.level is not an array or is undefined');
                    }

                    return acc;
                }, {});
                if (!signal.aborted) {
                    setSudotetrisPuzzles(data);
                }
            } catch (error) {
                if (!signal.aborted) {
                }
            }
        };

        fetchData();
    }, [game_id]);

    const drawSudotetris = (canvas, sudotetrisData) => {
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const size = 9; // Sudoku is always a 9x9 grid
        const cellSize = 40; // Define the size of each cell in pixels
        canvas.width = size * cellSize;
        canvas.height = size * cellSize;

        // Predefined contrasting color palette
            const colors = [
                '#e6194B', '#3cb44b', '#c0a600', '#4363d8', '#f58231',
                '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe',
                '#008080', '#e6beff', '#9a6324', '#fff259', '#800000',
                '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'
            ];

        // Hash function to determine index in the colors array
        const hashId = (id) => {
            let hash = 0;
            for (let i = 0; i < id.length; i++) {
                const char = id.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash = hash & hash; // Convert to 32bit integer
            }
            return Math.abs(hash) % colors.length;
        };

        // Color assignment based on ID to ensure contrast
        const colorMap = {};
        const neighborColors = Array(size).fill(null).map(() => Array(size).fill(null));

        const getColor = (id, x, y) => {
            if (!colorMap[id]) {
                let availableColors = colors.slice(); // Copy the colors array
                // Remove colors used by neighbors
                if (x > 0 && neighborColors[y][x-1]) { // Check left
                    availableColors = availableColors.filter(color => color !== neighborColors[y][x-1]);
                }
                if (y > 0 && neighborColors[y-1][x]) { // Check above
                    availableColors = availableColors.filter(color => color !== neighborColors[y-1][x]);
                }

                // Assign a color from the available colors based on hash
                const hashIndex = hashId(id);
                // Ensure that the chosen color is not used by immediate neighbors
                const colorIndex = availableColors.indexOf(colors[hashIndex % availableColors.length]);
                colorMap[id] = availableColors[colorIndex >= 0 ? colorIndex : 0];
            }
            neighborColors[y][x] = colorMap[id];
            return colorMap[id];
        };

        // Drawing cells
        sudotetrisData.forEach((cell, index) => {
            const x = index % size;
            const y = Math.floor(index / size);

            if (cell.includes('.')) {
                const parts = cell.split('.');
                const id = parts[2]; // ID is the third part of the format
                ctx.fillStyle = getColor(id, x, y);
            } else {
                ctx.fillStyle = 'white'; // Only numbers get white color
            }

            ctx.fillRect(x * cellSize, y * cellSize, cellSize, cellSize);
        });

        // Drawing grid lines for Sudoku board
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        for (let i = 0; i <= size; i++) {
            ctx.beginPath();
            ctx.moveTo(i * cellSize, 0);
            ctx.lineTo(i * cellSize, size * cellSize);
            ctx.moveTo(0, i * cellSize);
            ctx.lineTo(size * cellSize, i * cellSize);
            ctx.stroke();
        }

        // Thicker lines to separate 3x3 sections
        ctx.lineWidth = 3;
        for (let i = 0; i <= size; i += 3) {
            ctx.beginPath();
            ctx.moveTo(i * cellSize, 0);
            ctx.lineTo(i * cellSize, size * cellSize);
            ctx.moveTo(0, i * cellSize);
            ctx.lineTo(size * cellSize, i * cellSize);
            ctx.stroke();
        }
    };



    useEffect(() => {
        if (game_id === null) return;
        if (!changeSudotetris) return;
        const changeChallengePost = async () => {
            // console.log(currentSudotetris.name);
            // return;
            const name = currentSudotetris.name;
            switch (method) {
                case "changedate":
                    const response = await fetch(process.env.REACT_APP_API_URL_STATISTICS + "?method=getAllowedToScheduleNonogram&gameid=" + game_id + "&date=" + rescheduleDate, {
                        method: 'GET',
                        credentials: 'include',
                    });
                    const scheduleAllowedData = await response.json();
                    // console.log("ALLOWED IS= "+scheduleAllowedData.result);
                    if (scheduleAllowedData.result === 0) {
                        showAlert(<span>Unable to schedule: The selected date either already has a Sudotetris or is in the past.</span>, "error");
                        return;
                    }
                    // console.log(scheduleAllowedData.result);
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeDate",
                            nonogram: name,
                            date: rescheduleDate,
                            gameid: game_id
                        })
                    });
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeStatus",
                            nonogram: name,
                            status: "scheduled",
                            gameid: game_id
                        })
                    });
                    showAlert(<span>Succesfully changed date to {rescheduleDate}!</span>, "success");
                    updateSudotetrisPuzzles("scheduled");
                    break;
                case "setlevel":
                    const response1 = await fetch(process.env.REACT_APP_API_URL_STATISTICS + "?method=getAllowedToPlaceLevel&gameid=" + game_id + "&level=" + selectedLevel, {
                        method: 'GET',
                        credentials: 'include',
                    });
                    const scheduleAllowedData1 = await response1.json();
                    console.log("ALLOWED IS= ", scheduleAllowedData1.result[0], " and type is ", typeof scheduleAllowedData1.result);

                    if (scheduleAllowedData1.result[0] === 0) {
                        showAlert(<span>Unable to put in level, level {selectedLevel} already exists.</span>, "error");
                        return;
                    }
                    // console.log(scheduleAllowedData.result);
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeLevel",
                            nonogram: name,
                            level: selectedLevel,
                            gameid: game_id
                        })
                    });
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeStatus",
                            nonogram: name,
                            status: "level",
                            gameid: game_id
                        })
                    });
                    showAlert(<span>Put puzzle {name} on level {selectedLevel}!</span>, "success");
                    updateSudotetrisPuzzles("level");
                    break;
                case "reject":
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeStatus",
                            nonogram: name,
                            status: "rejected",
                            gameid: game_id
                        })
                    });
                    showAlert(
                        <span>Succesfully changed status to rejected for <strong>{name}</strong>!</span>, "success");
                    updateSudotetrisPuzzles("rejected");
                    break;
                case "reuse":
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "updateChallengeStatus",
                            nonogram: name,
                            status: "available",
                            gameid: game_id
                        })
                    });
                    showAlert(
                        <span>Succesfully changed status to available for <strong>{name}</strong>!</span>, "success");
                    updateSudotetrisPuzzles("available");
                    break;
                case "delete":
                    await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify({
                            method: "deleteChallenge",
                            nonogram: name,
                            gameid: game_id
                        })
                    });
                    showAlert(<span>Succesfully deleted <strong>{name}</strong>!</span>, "success");
                    deleteSudotetrisLive();
                    break;
            }
        }
        changeChallengePost();
        setChangeSudotetris(false);
    }, [changeSudotetris]);

    const selectSudotetris = (sudotetris) => {
        setCurrentSudotetris(sudotetris);
        setOpenLevelDialog(true);
    };


    const updateSudotetrisPuzzles = (newStatus) => {
        let name = currentSudotetris.name;
        let newDate = newStatus === 'scheduled' ? rescheduleDate : currentSudotetris.date;
        let data = currentSudotetris.data;
        // let size = currentSudotetris.size;
        let difficulty = currentSudotetris.difficulty;
        let level = currentSudotetris.level;
        setSudotetrisPuzzles(prevState => {
            let newScheduled = Array.isArray(prevState.scheduled) ? [...prevState.scheduled] : [];
            let newAvailable = Array.isArray(prevState.available) ? [...prevState.available] : [];
            let newUsed = Array.isArray(prevState.used) ? [...prevState.used] : [];
            let newRejected = Array.isArray(prevState.rejected) ? [...prevState.rejected] : [];
            let newLevel = Array.isArray(prevState.level) ? [...prevState.level] : [];

            // Remove the sudotetris puzzle from all arrays
            newScheduled = newScheduled.filter(sudotetris => sudotetris.name !== name);
            newAvailable = newAvailable.filter(sudotetris => sudotetris.name !== name);
            newUsed = newUsed.filter(sudotetris => sudotetris.name !== name);
            newRejected = newRejected.filter(sudotetris => sudotetris.name !== name);
            newLevel = newLevel.filter(sudotetris => sudotetris.name !== name);

            // Create a new sudotetris puzzle object
            const updatedSudotetris = { name: name, date: newDate, status: newStatus, data: data , difficulty: difficulty, level: level};

            // Add the sudotetris puzzle to the correct array based on the new status
            switch (newStatus) {
                case 'scheduled':
                    newScheduled.push(updatedSudotetris);
                    break;
                case 'available':
                    newAvailable.push(updatedSudotetris);
                    break;
                case 'used':
                    newUsed.push(updatedSudotetris);
                    break;
                case 'rejected':
                    newRejected.push(updatedSudotetris);
                    break;
                case 'level':
                    newLevel.push(updatedSudotetris);
                    break;
                default:
                    break;
            }

            return {
                scheduled: newScheduled,
                available: newAvailable,
                used: newUsed,
                rejected: newRejected,
                level: newLevel
            };
        });
    };

    const deleteSudotetrisLive = () => {
        let name = currentSudotetris.name;
        setSudotetrisPuzzles(prevState => {
            let newRejected = prevState.rejected.filter(sudotetris => sudotetris.name !== name);
            return { ...prevState, rejected: newRejected };
        });
    };

    const rescheduleSudotetris = (sudotetris) => {
        const initialDate = sudotetris.date || new Date().toISOString().slice(0, 10);
        setRescheduleDate(initialDate);
        setCurrentSudotetris(sudotetris);
        setOpenRescheduleDialog(true);
    };

    const reuseSudotetris = async (sudotetris) => {
        setCurrentSudotetris(sudotetris);
        setMethod("reuse");
        setChangeSudotetris(true);
    };

    const rejectSudotetris = async (sudotetris) => {
        setCurrentSudotetris(sudotetris);
        setMethod("reject");
        setChangeSudotetris(true);
    };

    const handleDeleteConfirmation = async () => {
        setOpenDeleteDialog(false);
        setMethod("delete");
        setChangeSudotetris(true);
    };

    const deleteSudotetris = (sudotetris) => {
        setCurrentSudotetris(sudotetris);
        setOpenDeleteDialog(true);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const renderButtons = (sudotetris) => {
        switch (activeTab) {
            case 'scheduled':
                return (
                    <>
                        <Tooltip title="Change the scheduled date. Ensure to manually schedule another sudotetris to maintain game continuity.">
                            <Button variant="contained" onClick={() => rescheduleSudotetris(sudotetris)} color="success">Change Date</Button>
                        </Tooltip>
                        <Tooltip title="Reject this sudotetris, moving it to the rejection bucket. Ensure to manually schedule another sudotetris to maintain game continuity.">
                            <Button variant="contained" color="error" onClick={() => rejectSudotetris(sudotetris)}>Reject</Button>
                        </Tooltip>
                    </>
                );
            case 'available':
                return (
                    <>
                        <Tooltip title="Select this sudotetris for a level.">
                            <Button variant="contained" color="primary" onClick={() => selectSudotetris(sudotetris)}>Select for level</Button>
                        </Tooltip>
                        <Tooltip title="Schedule this sudotetris for use.">
                            <Button variant="contained" onClick={() => rescheduleSudotetris(sudotetris)} color="success">Schedule</Button>
                        </Tooltip>
                        <Tooltip title="Reject this sudotetris, moving it to the rejection bucket.">
                            <Button variant="contained" color="error" onClick={() => rejectSudotetris(sudotetris)}>Reject</Button>
                        </Tooltip>
                    </>
                );
            case 'used':
                return (
                    <>
                        <Tooltip title="Change the date, and schedule this sudotetris again.">
                            <Button variant="contained" onClick={() => rescheduleSudotetris(sudotetris)} color="success">Reschedule</Button>
                        </Tooltip>
                        <Tooltip title="Return this sudotetris to the available bucket.">
                            <Button variant="contained" onClick={() => reuseSudotetris(sudotetris)} color="secondary">Reuse</Button>
                        </Tooltip>
                    </>
                );
            case 'rejected':
                return (
                    <>
                        <Tooltip title="Return this sudotetris to the available bucket.">
                            <Button variant="contained" onClick={() => reuseSudotetris(sudotetris)} color="secondary">Reuse</Button>
                        </Tooltip>
                        <Tooltip title="Delete this sudotetris permanently from the database.">
                            <Button variant="contained" color="error" onClick={() => deleteSudotetris(sudotetris)}>Delete</Button>
                        </Tooltip>
                    </>
                );
            case 'level':
                return (
                    <>
                        <Tooltip title="Return this sudotetris to the available bucket.">
                            <Button variant="contained" onClick={() => reuseSudotetris(sudotetris)} color="secondary">Return to available</Button>
                        </Tooltip>
                        <Tooltip title="Reject this sudotetris, moving it to the rejection bucket.">
                            <Button variant="contained" color="error" onClick={() => rejectSudotetris(sudotetris)}>Reject</Button>
                        </Tooltip>
                    </>
                );
            default:
                return null;
        }
    };

    const renderSudotetrisList = (showDate = false, showLevel = false) => (
        <div>
            {(sudotetrisPuzzles[activeTab] || []).map((sudotetris, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <canvas ref={canvas => drawSudotetris(canvas, sudotetris.data.split(','))}
                            style={{ marginRight: '10px', width: '100px', height: '100px' }}></canvas>
                    <div>
                        {/*<div>Name: {sudotetris.name}</div>*/}
                        {showLevel && <div>Level: {sudotetris.level}</div>}
                        <div>Difficulty: {sudotetris.difficulty}</div>
                        {showDate && <div>Date: {sudotetris.date || 'null'}</div>}
                        {renderButtons(sudotetris)}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
                <Button variant="outlined" onClick={() => handleTabChange('scheduled')}
                        color={activeTab === 'scheduled' ? 'primary' : 'inherit'}>Scheduled</Button>
                <Button variant="outlined" onClick={() => handleTabChange('available')}
                        color={activeTab === 'available' ? 'primary' : 'inherit'}>Available</Button>
                <Button variant="outlined" onClick={() => handleTabChange('used')}
                        color={activeTab === 'used' ? 'primary' : 'inherit'}>Used</Button>
                <Button variant="outlined" onClick={() => handleTabChange('rejected')}
                        color={activeTab === 'rejected' ? 'primary' : 'inherit'}>Rejected</Button>
                <Button variant="outlined" onClick={() => handleTabChange('level')}
                        color={activeTab === 'level' ? 'primary' : 'inherit'}>Level</Button>
            </div>
            <div style={{
                maxHeight: '500px',
                minHeight: '500px',
                overflowY: 'auto'
            }}>
                {renderSudotetrisList(activeTab === 'scheduled' || activeTab === 'used', activeTab === 'level')}
            </div>
            {/* Dialog for rescheduling a sudotetris */}
            <Dialog open={openRescheduleDialog} onClose={() => setOpenRescheduleDialog(false)}>
                <DialogTitle>Reschedule Sudotetris</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="date"
                        label="Date"
                        type="date"
                        fullWidth
                        variant="standard"
                        value={rescheduleDate}
                        inputProps={{
                            min: today,  // sets the minimum date to today
                        }}
                        onChange={e => setRescheduleDate(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRescheduleDialog(false)}>Cancel</Button>
                    <Button onClick={async () => {
                        setOpenRescheduleDialog(false);
                        setMethod("changedate");
                        setChangeSudotetris(true);
                    }}>Implement</Button>
                </DialogActions>
            </Dialog>
            {/* New dialog for deleting a sudotetris */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this sudotetris? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmation} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openLevelDialog} onClose={() => setOpenLevelDialog(false)}>
                <DialogTitle>Select Sudotetris Level</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="level"
                        label="Level"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={selectedLevel}
                        inputProps={{ min: 1, max: 50 }}
                        onChange={(e) => setSelectedLevel(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLevelDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        if (selectedLevel < 1 || selectedLevel > 50) {
                            setOpenLevelDialog(false);
                            showAlert(<span>Level needs to be between 1 and 50!</span>, "error");
                            return;
                        }
                        setOpenLevelDialog(false);
                        setMethod("setlevel");
                        setChangeSudotetris(true);
                        // showAlert(<span>Selected level {selectedLevel} for {currentSudotetris.name}</span>, "success");
                    }}>Set</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default ExistingSudotetrisViewer;
