import React, { useState, useEffect} from 'react';
import './login_form.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import crypto from 'crypto';
import {useSidebarContext} from "../../Sidebar/sidebar_context";
import {IconButton, InputAdornment, Stack, Input} from "@mui/material";
import LoadingScreenDenda from "../Animation/loading_screen";

const LoginForm = () => {
    const {
        authenticated,
        setAuthenticated,
        setBrand,
        setAccess,
        setDates,
        setComponents,
        setPages,
        setUser,
        setGames,
        setUserChildren
    } = useSidebarContext();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); //set state for showing password
    const [loginError, setLoginError] = useState(false);
    const [loading, setLoading] = useState(false); // State for controlling the loading screen

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // padStart ensures the month is always 2 digits
    const day = String(currentDate.getDate()).padStart(2, '0'); // padStart ensures the day is always 2 digits
    const formattedDate = `${year}-${month}-${day}`;

    // Function to hash password
    const hashPassword = (password) => {
        const hash = crypto.createHash('sha256');
        hash.update(password);
        return hash.digest('hex');
    };

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL_AUTH + '/login/session';
        console.log('API URL:', apiUrl);
        const requestOptions = {
            method: 'POST',
            credentials: 'include'
        }

        fetch(apiUrl, requestOptions)
            .then(response => {
                console.log('Response Status:', response.status);
                console.log('Response OK:', response.ok);

                if (!response.ok) { // Checks if the status code is outside the 200-299 range
                    throw new Error('HTTP status ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                console.log('Response Data:', data);

                if (data['isValid']) {
                    setAuthenticated(data['isValid']);
                    setUser(data['username']);
                    console.log('User authenticated:', data['isValid']);
                    console.log('Username:', data['username']);
                } else {
                    console.log('User not authenticated');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);


    // Function to handle the login process
    const handleLogin = () => {
        setLoading(true);
        const hashedPassword = hashPassword(password);
        const postData = {
            "username": username,
            "hash": hashedPassword
        }
        fetch(process.env.REACT_APP_API_URL_AUTH + '/login', {
            method: 'POST',
            body: JSON.stringify(postData),
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if(data['isValid']){
                    setAuthenticated(data['isValid']);
                    setUser(data['username']);
                }
                else {
                    setLoginError(true);
                    setTimeout(() => setLoginError(true), 3000); // Reset loginError after 3 seconds
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        setLoading(false);
    };

    // Function to handle the logout process
    const handleLogout = () => {
        const url = process.env.REACT_APP_API_URL_AUTH + "/logout";
        const requestOptions = {
            method: 'POST',
            credentials: 'include',  // This includes cookies in the request
            headers: {
                // Any custom headers can be logged and set here
                'Content-Type': 'application/json'
            }
        };


        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("Response received:", data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        // localStorage.clear();
        setAuthenticated(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Stack spacing='var(--spacing-S)'>
            <Stack spacing={'var(--spacing-XXS)'}>
                <div className="login-container">
                    {loading ? ( // Show loading component when loading is true
                            <LoadingScreenDenda text={"Loading"}/>
                    ) : authenticated ? ( // Conditionally render only when not loading and not authenticated
                        <div className="login-form">
                            <div className="form-group">
                                <Input
                                    value={""}
                                    disabled
                                    className={"input-disabled"}
                                />
                            </div>
                            <div className="form-group">
                                <Input
                                    value={""}
                                    disabled
                                    className={"input-disabled"}
                                />
                            </div>
                            <div>
                                <button type="submit" style={{marginTop: "8px"}} className="logout-button"
                                        onClick={handleLogout}>
                                    LOG OUT
                                    {/*{loading && <Loading_icon text="Logging out"/>}*/}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="login-form">
                            <div className="form-group">
                                <Input
                                    type="username"
                                    placeholder="Username"
                                    required
                                    value={username}
                                    onKeyPress={handleKeyPress} // Add key press handler
                                    onChange={(e) => setUsername(e.target.value)}
                                    className={loginError ? 'input-error' : ''}
                                />
                            </div>
                            <div className="form-group">
                                {/* Input for the password with conditional type based on showPassword state */}
                                <Input
                                    type={showPassword ? 'text' : "password"}
                                    placeholder="Password"
                                    required
                                    onKeyPress={handleKeyPress} // Add key press handler
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={loginError ? 'input-error' : ''}
                                    // endAdornment={
                                    //     <InputAdornment position="start">
                                    //         <IconButton onClick={() => setShowPassword(!showPassword)}
                                    //                     // edge="end"
                                    //             >
                                    //             {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                    //         </IconButton>
                                    //     </InputAdornment>
                                    // }
                                />
                            </div>
                            <button type="submit" className="login-button" onClick={handleLogin} onKey>
                                LOG IN
                                {/*{loading && <Loading_icon text="Logging in"/>}*/}

                            </button>
                            <div className={loginError ? 'error-message' : ''}>
                                {loginError ? 'Wrong username or password.' : ''}
                            </div>
                        </div>
                    )}
                    {/*{loading && <Loading_icon/>}*/}
                </div>
            </Stack>
        </Stack>
    );
}

export default LoginForm;